import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

//  GET tasks list
export const fetchTasksListSlice = createAsyncThunk(
    'tasks/fetchAll',
    async (opt={perpage: 15, archive: false}, thunkAPI) => {
        try {
            const res = await axios.get(`/api/tasks?perpage=100&archive=${opt.archive}`);

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  GET reports list
export const fetchReportsListSlice = createAsyncThunk(
    'tasks/fetchAllReports',
    async (perpage=15, thunkAPI) => {
        try {
            const res = await axios.get('/api/reports?perpage=100');

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  GET task
export const fetchTaskSlice = createAsyncThunk(
    'tasks/fetchTask',
    async (id, thunkAPI) => {
        try {
            const res = await axios.get(`/api/task/${id}`);

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  POST create task
export const addTaskSlice = createAsyncThunk(
    'tasks/addTask',
    async (taskData, thunkAPI) => {
        try {
            const res = await axios.post('/api/task', taskData);

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  PATCH task by admin
export const editTaskSlice = createAsyncThunk(
    'tasks/editTask',
    async (taskData, thunkAPI) => {
        try {
            const res = await axios.patch(`/api/task`, taskData);

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  PATCH task by tester
export const editReportSlice = createAsyncThunk(
    'tasks/editReport',
    async (reportData, thunkAPI) => {
        try {
            const res = await axios.patch(`/api/report`, reportData);
            
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const closeReportSlice = createAsyncThunk(
    'tasks/editReport',
    async (reportData, thunkAPI) => {
        try {
            const res = await axios.patch(`/api/report?status=done`, reportData);

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  DELETE task
export const deleteTaskSlice = createAsyncThunk(
    'tasks/editTask',
    async (taskID, thunkAPI) => {
        try {
            // console.log('taskID', taskID)
            await axios.delete(`/api/task/${taskID}`);

            return taskID;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addActiveTaskID = createAsyncThunk(
    'tasks/addActiveTaskID',
    async (taskID, thunkAPI) => {
        try {
            return taskID;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const refreshActiveTaskID = createAsyncThunk(
    'tasks/refreshActiveTaskID',
    async (taskID, thunkAPI) => {
        try {
            return taskID;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const changingSaveValue = createAsyncThunk(
    'tasks/changingSaveValue',
    async (value, thunkAPI) => {
        try {
            return value;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateTestsInputData = createAsyncThunk(
    'tasks/updateTestsInputData',
    async (data, thunkAPI) => {
        try {
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const resetTestsInputData = createAsyncThunk(
    'tasks/resetTestsInputData',
    async (_, thunkAPI) => {
        try {
            return _;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
