import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

import { selectIsLoggedIn, selectIsRefreshing } from "../redux/slices/auth/selectors";


export const PrivateRoute = ({ component: Component, redirectTo = '/' }) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isRefreshing = useSelector(selectIsRefreshing);


    const shouldRedirect = !isLoggedIn && !isRefreshing;

    return shouldRedirect ? <Navigate to={redirectTo} /> : Component;
};

export default PrivateRoute;

// import { Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { selectIsLoggedIn, selectIsRefreshing } from "../redux/slices/auth/selectors";


// function PrivateRoute({ children }) {
//         const isAuthenticated = useSelector(selectIsLoggedIn);



//     return isAuthenticated ? children : <Navigate to="/login" />;
// }

// export default PrivateRoute;
