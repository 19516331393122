import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

//  FETCH all users data
export const getAllAuthorizedUsers = createAsyncThunk(
    'reports/getAllUsers',
    async (token, thunkAPI) => {
        try {
            const res = await axios.get(`https://api.clickup.com/api/v2/team`, token);

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const getSpaceFolders = (space) => {
    let folders = space.folders.map(folder => ({id: folder.id, label: folder.name, type: 'folder', children: getFolderLists(folder)}));

    return folders;
}

const getFolderLists = (folder) => {
    let lists = folder.lists.map(list => ({id: list.id, label: list.name, type: 'list'}));

    return lists;
}


export const getTree = createAsyncThunk(
    'reports/getTree',
    async (data, thunkAPI) => {
        const { spaces, query, token } = data

        try {
            let treeArr = [];

            for (let i = 0; i < spaces.length; i++) {

                const folder = await axios.get(`https://api.clickup.com/api/v2/space/${spaces[i].id}/folder?${query}`, token);

                const list = await axios.get(`https://api.clickup.com/api/v2/space/${spaces[i].id}/list?${query}`, token);

                let spaceChildrens = [];
                let spaceFolders = [];
                let spaceLists = [];

                spaceFolders = await getSpaceFolders(folder.data);

                spaceLists = await getFolderLists(list.data);

                spaceChildrens = [...spaceFolders, ...spaceLists];


                treeArr.push({ id: spaces[i].id, label: spaces[i].name, type: 'space', children: spaceChildrens })

            }

            return treeArr;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


export const getSpaces = createAsyncThunk(
    'reports/getSpaces',
    async (data, thunkAPI) => {
        const { headers, teamID, searchParams } = data
        try {
            const res = await axios.get(`https://api.clickup.com/api/v2/team/${teamID}/space?${searchParams}`, headers);

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getGroups = createAsyncThunk(
    'reports/getGroups',
    async (data, thunkAPI) => {
        const { headers, query } = data
        try {

            const res = await axios.get(`https://api.clickup.com/api/v2/group?${query}`, headers);

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const getTimeEntryes = createAsyncThunk(
    'reports/fetchTime',
    async (data, thunkAPI) => {
        const { 
            token,    
            teamID,
            sd,
            ed,
            allUsersIDsArr,
            settingsData,
        } = data
        try {
            let res = [];


            for (let i = 0; i < settingsData.length; i++) {
                const query = new URLSearchParams({
                    start_date: sd,
                    end_date: ed,
                    assignee: allUsersIDsArr,
                    space_id: settingsData[i].type === 'space' ? settingsData[i].id : '',
                    folder_id: settingsData[i].type === 'folder' ? settingsData[i].id : '',
                    list_id: settingsData[i].type === 'list' ? settingsData[i].id : '',
                }).toString();

                const data = await axios.get(`https://api.clickup.com/api/v2/team/${teamID}/time_entries?${query}`, token);

                if (data.data.data.length) {
                    res.push({ folderName: settingsData[i].name, data: data.data.data });
                }
            }
            return res;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


//  GET templates reports list
export const fetchReportsList = createAsyncThunk(
    'reports/fetchReportsList',
    async(thunkAPI) => {
    try {
        const res = await axios.get(`/api/templates`);

        return res.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
}
);

//  GET template report by ID
export const fetchReport = createAsyncThunk(
    'reports/fetchReport',
    async (templateID, thunkAPI) => {
    try {
        const res = await axios.get(`/api/templates/${templateID}`);

        return res.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
}
);

//  Create template report
export const createTemplateReport = createAsyncThunk(
    'reports/createTemplateReport',
    async (templateData, thunkAPI) => {
    try {
        await axios.post(`/api/templates`, JSON.stringify(templateData));

        // fetchReportsList();
        return templateData;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
}
);

export const changeTemplateReportStatus = createAsyncThunk(
    'reports/changeTemplateReportStatus',
    async (status, thunkAPI) => {
        try {
            
            return status;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  Update template report
export const updateTemplateReport = createAsyncThunk(
    'reports/updateTemplateReport',
    async (data, thunkAPI) => {
    try {
        const res = await axios.put(`/api/templates/${data.currentTempalteID}`, JSON.stringify(data.updTemplate));

        return res.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
}
);

export const changeUpdateTemplateReportStatus = createAsyncThunk(
    'reports/changeUpdateTemplateReportStatus',
    async (status, thunkAPI) => {
        try {

            return status;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


//  Delete template report
export const deleteTemplateReport = createAsyncThunk(
    'reports/deleteTemplateReport',
    async (templateID, thunkAPI) => {
    try {
        const res = await axios.delete(`/api/templates/${templateID}`);

        return res.status;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
}
);

export const changeDeleteTemplateReportStatus = createAsyncThunk(
    'reports/changeDeleteTemplateReportStatus',
    async (status, thunkAPI) => {
        try {

            return status;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
