import { createSlice } from '@reduxjs/toolkit';
import { getAllAuthorizedUsers, 
    getSpaces, 
    getTree,
    getGroups,
    getTimeEntryes,
    fetchReportsList,
    fetchReport,
    createTemplateReport,
    updateTemplateReport,
    deleteTemplateReport,
    changeTemplateReportStatus,
    changeUpdateTemplateReportStatus,
    changeDeleteTemplateReportStatus,
 } from './operations';
import { logOut } from '../auth/operations';

const handleRejected = (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
};

const handlePending = state => {
    state.isLoading = true;
    state.error = null;
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        // pagination: {},
        users: [],
        reports: [],
        folders: [],
        spaces: [],
        spaceLists: [],
        tree: [],
        groups: [],
        timeEntryes: [],
        templates: [],
        template: [],
        isLoading: false,
        error: null,
        isTemplateSave: false,
        isTemplateUpdate: false,
        isTemplateDelete: false,
    },
    extraReducers: builder => {
        builder
            .addCase(getAllAuthorizedUsers.pending, handlePending)
            .addCase(getAllAuthorizedUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.users = action.payload.teams[0].members;
                // state.pagination = action.payload.pagination;
            })
            .addCase(getAllAuthorizedUsers.rejected, handleRejected)

            .addCase(getTree.pending, handlePending)
            .addCase(getTree.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.tree = action.payload;
            })
            .addCase(getTree.rejected, handleRejected)

            .addCase(getGroups.pending, handlePending)
            .addCase(getGroups.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.groups = action.payload.groups;
            })
            .addCase(getGroups.rejected, handleRejected)

            .addCase(getSpaces.pending, handlePending)
            .addCase(getSpaces.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.spaces = action.payload.spaces;
            })
            .addCase(getSpaces.rejected, handleRejected)

            .addCase(getTimeEntryes.pending, handlePending)
            .addCase(getTimeEntryes.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.timeEntryes = action.payload;
            })
            .addCase(getTimeEntryes.rejected, handleRejected)

            .addCase(fetchReportsList.pending, handlePending)
            .addCase(fetchReportsList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.templates = action.payload;
            })
            .addCase(fetchReportsList.rejected, handleRejected)

            .addCase(fetchReport.pending, handlePending)
            .addCase(fetchReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.template = action.payload.template;
            })
            .addCase(fetchReport.rejected, handleRejected)

            .addCase(createTemplateReport.pending, handlePending)
            .addCase(createTemplateReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTemplateSave =true;
            })
            .addCase(createTemplateReport.rejected, handleRejected)

            .addCase(changeTemplateReportStatus.pending, handlePending)
            .addCase(changeTemplateReportStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTemplateSave = action.payload;
            })
            .addCase(changeTemplateReportStatus.rejected, handleRejected)

            .addCase(updateTemplateReport.pending, handlePending)
            .addCase(updateTemplateReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTemplateUpdate = true;
            })
            .addCase(updateTemplateReport.rejected, handleRejected)

            .addCase(changeUpdateTemplateReportStatus.pending, handlePending)
            .addCase(changeUpdateTemplateReportStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTemplateUpdate = action.payload;
            })
            .addCase(changeUpdateTemplateReportStatus.rejected, handleRejected)

            .addCase(deleteTemplateReport.pending, handlePending)
            .addCase(deleteTemplateReport.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTemplateDelete = true;
            })
            .addCase(deleteTemplateReport.rejected, handleRejected)

            .addCase(changeDeleteTemplateReportStatus.pending, handlePending)
            .addCase(changeDeleteTemplateReportStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.isTemplateDelete = action.payload;
            })
            .addCase(changeDeleteTemplateReportStatus.rejected, handleRejected)

    },
});

export default reportsSlice.reducer;
