import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: [],
    activeTaskID: 1
}

export const completedTasks = createSlice({
    name: 'completedTasks',
    initialState,
    reducers: {
        getTasks: (state, tasks) => {
            state.value = tasks.payload
        },
        setActiveTaskID: (state, id) => {
            state.activeTaskID = id.payload
        },
        addCompletedTask: (state, newTask) => {
            state.value = [...state.value, newTask.payload]
        },
        editTask: (state, editedTask) => {
            state.value = [...state.value.filter(el => el.id !== editedTask.payload.id), editedTask.payload];
        },
        deleteTask: (state, id) => {
            state.value = [...state.value.filter(el => el.id !== id.payload)];
        },
    },
  })

export const { getTasks, addCompletedTask, setActiveTaskID, editTask, deleteTask } = completedTasks.actions;

export default completedTasks.reducer;
