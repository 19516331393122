import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

// axios.defaults.baseURL = 'https://goit-task-manager.herokuapp.com/';

// // Utility to add JWT
// const setAuthHeader = () => {
//     axios.defaults.headers.common.Authorization = true;
// };

// // // Utility to remove JWT
// const clearAuthHeader = () => {
//     axios.defaults.headers.common.Authorization = false;
// };

/*
 * POST @ /users/signup
 * body: { name, email, password }
 */
// export const register = createAsyncThunk(
//     'auth/register',
//     async (credentials, thunkAPI) => {
//         try {
//             const res = await axios.post('/users/signup', credentials);
//             // After successful registration, add the token to the HTTP header
//             setAuthHeader(res.data.token);
//             return res.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

/*
 * POST @ /users/login
 * body: { email, password }
 */
export const logIn = createAsyncThunk(
    'auth/login',
    async (credentials, thunkAPI) => {
        try {
            const res = await axios.post('/api/signin', credentials);
            // After successful login, add the token to the HTTP header
            // setAuthHeader();
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);

/*
 * POST @ /users/logout
 * headers: Authorization: Bearer token
 */
export const logOut = createAsyncThunk(
    'auth/logout', 
    async (_, thunkAPI) => {
    try {
        await axios.get('/api/signout');
        // After a successful logout, remove the token from the HTTP header
        // clearAuthHeader();
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
});

/*
 * GET @ /users/current
 * headers: Authorization: Bearer token
 */
export const refreshUser = createAsyncThunk(
    'auth/refresh',
    async (_, thunkAPI) => {
        // Reading the token from the state via getState()
        const state = thunkAPI.getState();
        const isLoggedIn = state.auth.isLoggedIn;
        // console.log('isLoggedIn', isLoggedIn)

        if (!isLoggedIn) {
            // If there is no token, exit without performing any request
            return thunkAPI.rejectWithValue('Unable to fetch user');
        }

        try {
            // If there is a token, add it to the HTTP header and perform the request
            // setAuthHeader();
                const res = await axios.get('/api/profile');
                return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// PATCH 
export const updateProfileInfo = createAsyncThunk(
    'auth/updateProfile',
    async (text, thunkAPI) => {
        // console.log(text)

        try {
            const response = await axios.patch('/api/profile', text);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

