import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// GET 
export const fetchUsers = createAsyncThunk(
    'users/fetchAll',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const isLoggedIn = state.auth.isLoggedIn;

        try {
            if (isLoggedIn) {
                const res = await axios.get('/api/users?perpage=100');
                return res.data;
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// POST 
export const addUser = createAsyncThunk(
    'users/addUser',
    async (text, thunkAPI) => {
        try {
            const response = await axios.post('/api/admin/user', text);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

// DELETE 
export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (id, thunkAPI) => {
        try {
            const response = await axios.delete(`/api/admin/user/${id}`);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

// PATCH 
export const updateUser = createAsyncThunk(
    'users/updateUser',
    async (text, thunkAPI) => {
        // console.log(text)

        try {
            const response = await axios.patch('/api/admin/user', text);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

export const addUsersList = createAsyncThunk(
    'users/addAll',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const isLoggedIn = state.auth.isLoggedIn;

        try {
            if (isLoggedIn) {
                const res = await axios.get('/api/users?perpage=100');

                // const allTesters = await res.data.data.filter(user => user.role === 'tester');

                // const testersNamesList = [];

                // allTesters.forEach(tester => {
                //     testersNamesList.push(`${tester.first_name} ${tester.last_name}`);
                // });
                
                return res.data;
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
