import './App.css';
import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from "./assets/routes/ProtectedRoute";
import PrivateRoute from "./assets/routes/PrivateRoute";
import { useEffect, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshUser } from './assets/redux/slices/auth/operations';
import { selectIsRefreshing } from "./assets/redux/slices/auth/selectors";
import { Suspense } from 'react';

const Main = lazy(() => import('./assets/components/main/Main' /* webpackChunkName: "MainComponent" */));
const Login = lazy(() => import('./assets/pages/Login' /* webpackChunkName: "Login" */));
const Info = lazy(() => import('./assets/components/main/info/Info' /* webpackChunkName: "Info" */));
const Table = lazy(() => import('./assets/components/main/TasksTable' /* webpackChunkName: "TasksTable" */));
const Task = lazy(() => import('./assets/components/main/Tasks/TaskData' /* webpackChunkName: "TaskData" */));
const AddTask = lazy(() => import('./assets/components/main/Tasks/AddTask' /* webpackChunkName: "AddTask" */));
const CompleteTask = lazy(() => import('./assets/components/main/Tasks/CompleteTask' /* webpackChunkName: "CompleteTask" */));
const ArchiveTasks = lazy(() => import('./assets/components/main/Tasks/ArchiveTasks'/* webpackChunkName: "ArchiveTasks" */));
const ArchiveTask = lazy(() => import('./assets/components/main/Tasks/ArchiveTask'/* webpackChunkName: "ArchiveTask" */));
const UsersView = lazy(() => import('./assets/components/main/Users/UsersView' /* webpackChunkName: "UsersView" */));
const Firmware = lazy(() => import('./assets/components/main/firmware/Firmware' /* webpackChunkName: "Firmware" */));
const TicketsSystem = lazy(() => import('./assets/components/main/Tickets/TicketsSystem' /* webpackChunkName: "TicketsSystem" */));
const AddTicket = lazy(() => import('./assets/components/main/Tickets/AddTicket' /* webpackChunkName: "AddTicket" */));
const Ticket = lazy(() => import('./assets/components/main/Tickets/Ticket' /* webpackChunkName: "Ticket" */));
const Reports = lazy(() => import('./assets/components/main/reports/Reports' /* webpackChunkName: "Reports" */));
const DataBase = lazy(() => import('./assets/components/main/database/DataBase' /* webpackChunkName: "Reports" */));

export default function App() {
    const dispatch = useDispatch();
    const isRefreshing = useSelector(selectIsRefreshing);

    useEffect(() => {
        dispatch(refreshUser());
    }, [dispatch]);

    return isRefreshing ? (
        <b>Refreshing user...</b>
    ) : (
        <div className="App">
                <Suspense fallback={null}>
                    <Routes>
                        <Route path='/login'
                            element={
                                <ProtectedRoute component={<Login />} />
                            }
                        />
                        <Route path='/' 
                            element={ 
                                <PrivateRoute redirectTo="/login" component={<Main />}/>
                            } 
                        >
                            <Route path='info' 
                                element={
                                    <PrivateRoute redirectTo="/login" component={<Info />}/>
                                } 
                            />
                            <Route path='tasks' 
                                element={
                                    <PrivateRoute redirectTo="/login" component={<Table />}/>
                                }
                            />
                            <Route path='tasks/task/:id' 
                                element={
                                    <PrivateRoute redirectTo="/login" component={<Task />}/>
                                } 
                            />
                            <Route path='add-task' 
                                element={
                                    <PrivateRoute redirectTo="/login" component={<AddTask />}/>
                                } 
                            />
                            <Route path='tasks/task-complete/:id' 
                                element={
                                    <PrivateRoute redirectTo="/login" component={<CompleteTask />}/>
                                } 
                            />
                            <Route path='tasks/archive' 
                                element={
                                    <PrivateRoute redirectTo="/login" component={<ArchiveTasks />}/>
                                } 
                            />
                            <Route path='tasks/archive/:id'
                                element={
                                    <PrivateRoute redirectTo="/login" component={<ArchiveTask />}/>
                                } 
                            />
                            <Route path='firmware' 
                                element={
                                    <PrivateRoute redirectTo="/login" component={<Firmware />}/>
                                } 
                            />
                            <Route path='user-management' 
                                element={
                                    <PrivateRoute redirectTo="/login" component={<UsersView />}/>
                                } 
                            />
                            <Route path='tickets/'
                                element={
                                    <PrivateRoute redirectTo="/login" component={<TicketsSystem />} />
                                }
                            />
                            <Route path='tickets/add-ticket'
                                element={
                                    <PrivateRoute redirectTo="/login" component={<AddTicket />} />
                                }
                            />
                            <Route path='tickets/ticket/:id'
                                element={
                                    <PrivateRoute redirectTo="/login" component={<Ticket />} />
                                }
                            />
                            <Route path='reports'
                                element={
                                    <PrivateRoute redirectTo="/login" component={<Reports />} />
                                }
                            />
                            <Route path='dataBase/:id'
                                element={
                                    <PrivateRoute redirectTo="/login" component={<DataBase />} />
                                }
                            />
                            <Route path='*' 
                                element={<Navigate to="/" replace={true} />} 
                            />
                    </Route>

            </Routes>               
</Suspense>
         </div>
    );
}
