import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: 'Info',
}

export const currentSectionSlice = createSlice({
  name: 'currentSection',
  initialState,
  reducers: {
    setNew: (state, sectionName) => {
      state.value = sectionName.payload
    },
  },
})

export const { setNew } = currentSectionSlice.actions;

export default currentSectionSlice.reducer;
