import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// GET 
export const fetchTickets = createAsyncThunk(
    'tickets/fetchAll',
    async (_, thunkAPI) => {
        try {
            const res = await axios.get('/api/tickets?perpage=100');
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  GET Ticket
export const fetchTicket = createAsyncThunk(
    'tickets/fetchTicket',
    async (ticketID, thunkAPI) => {
        const state = thunkAPI.getState();
        const isLoggedIn = state.auth.isLoggedIn;

        try {
            if (isLoggedIn) {
                const res = await axios.get(`/api/ticket/${ticketID}`);

                return res.data;
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// POST 
export const addTicket = createAsyncThunk(
    'tickets/addTicket',
    async (ticket, thunkAPI) => {
        try {
            const response = await axios.post('/api/ticket', ticket);

            return response.status;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

// DELETE 
export const deleteTicket = createAsyncThunk(
    'tickets/deleteTicket',
    async (id, thunkAPI) => {
        try {
            await axios.delete(`/api/ticket/${id}`);
            return id;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

// PATCH 
export const updateTicket = createAsyncThunk(
    'tickets/updateTicket',
    async (text, thunkAPI) => {
        try {
            const response = await axios.patch('/api/ticket', text);

            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

//  Update ticket status
export const updateCurrentTicketStatus = createAsyncThunk(
    'tickets/updateCurrentTicketStatus',
    async (status, thunkAPI) => {
        try {
            return status;
        } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
        }
    }
);

export const resetCurrentTicket = createAsyncThunk(
    'tickets/resetCurrentTicket',
    async (_, thunkAPI) => {
        return [];
    }
);
