import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

//  GET product list
export const fetchProductListSlice = createAsyncThunk(
    'products/fetchAll',
    async (_, thunkAPI) => {
        try {
            const res = await axios.get('/api/products');
            // console.log('[FETCH Products]', res.data);
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// //  GET product list to adds create task
// export const fetchProductListForTask = createAsyncThunk(
//     'products/fetchAllProductListForTask',
//     async (_, thunkAPI) => {
//         try {
//             const res = await axios.get('/api/products');
//             // console.log('[FETCH Products]', res.data);
//             const firmwares = await axios.get(`/api/firmwares/${res.data[0].id}`);
//             console.log({
//                 product: res.data,
//                 firmwares
//             });

//             // return {
//             //     product: res.data,
//             //     firmwares
//             // };
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );

//  GET firmware
export const fetchFirmwaresSlice = createAsyncThunk(
    'firmwares/fetchAll',
    async (product_id, thunkAPI) => {
        try {
            const res = await axios.get(`/api/firmwares/${product_id}`);
            // const fm = await axios.get(`/api/firmwares`);
            // console.log('[PRODUCT ID]', product_id);
            // console.log('[FETCH FIRMWARES]', res);
            
            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  GET release notes
export const fetchReleaseNotesSlice = createAsyncThunk(
    'releasenotes/fetchAll',
    async (_, thunkAPI) => {
        try {
            const res = await axios.get('/api/releasenotes');

            return res.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

//  Patch move firmware to prod
export const moveFirmwareToProd = createAsyncThunk(
    'releasenotes/moveFirmwareToProd',
    async (id, thunkAPI) => {
        try {
            const res = await axios.patch(`/api/admin/firmware/release/prod/${id}`);

            return res.data;
        } catch (error) {
            // console.log('error', error)
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
);

export const changeMoveFirmwareToProdStatus = createAsyncThunk(
    'reports/changeMoveFirmwareToProdStatus',
    async (status, thunkAPI) => {
        try {

            return status;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// export const changeFirmwareStatusError = createAsyncThunk(
//     'reports/changeFirmwareStatusError',
//     async (status, thunkAPI) => {
//         try {

//             return status;
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.message);
//         }
//     }
// );
