import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../redux/slices/auth/selectors";


export const ProtectedRoute = ({ component: Component, redirectTo = '/' }) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);

    return isLoggedIn ? <Navigate to={redirectTo} /> : Component;
};

export default ProtectedRoute;


// import { Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { selectIsLoggedIn } from "../redux/slices/auth/selectors";

// function ProtectedRoute({ children }) {
//     const isAuthenticated = useSelector(selectIsLoggedIn);
    
//     return !isAuthenticated ? children : <Navigate to="/" />;
// }

// export default ProtectedRoute;
