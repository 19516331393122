import { createSlice } from '@reduxjs/toolkit';
import { logOut } from '../auth/operations';
import { fetchUsers, addUser, deleteUser, updateUser, addUsersList } from './operations';
import { updateProfileInfo } from '../auth/operations';


const handlePending = state => {
    state.isLoading = true;
};

const handleRejected = (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
};

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        pagination: {},
        items: [],
        usersList: [],
        isLoading: false,
        error: null,
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUsers.pending, handlePending)
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.items = action.payload.data;
                state.pagination = action.payload.pagination;
            })
            .addCase(fetchUsers.rejected, handleRejected)
            .addCase(addUser.pending, handlePending)
            .addCase(addUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.items.push(action.payload);
            })
            .addCase(addUser.rejected, handleRejected)
            .addCase(updateUser.pending, handlePending)
            .addCase(updateUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.items = state.items.map(item => { if (item.id === action.payload.id) { return action.payload; } return item; })
            })
            .addCase(updateUser.rejected, handleRejected)
            .addCase(updateProfileInfo.pending, handlePending)
            .addCase(updateProfileInfo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                state.items = state.items.map(item => { if (item.id === action.payload.id) { return action.payload; } return item; })
            })
            .addCase(updateProfileInfo.rejected, handleRejected)
            .addCase(deleteUser.pending, handlePending)
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                const index = state.items.findIndex(
                    user => user.id === action.payload.id
                );
                state.items.splice(index, 1);
            })
            .addCase(deleteUser.rejected, handleRejected)
            .addCase(addUsersList.rejected, handleRejected)
            .addCase(addUsersList.pending, handlePending)
            .addCase(addUsersList.fulfilled, (state, action) => {
                state.error = null;
                state.isLoading = false;
                state.items = action.payload.data;
                state.usersList = action.payload.data.filter(user => user.role === 'betatester').flatMap((person) => `${person.first_name} ${person.last_name}`);
            })
            .addCase(logOut.fulfilled, state => {
                state.pagination = {};
                state.items = [];
                state.usersList = [];
                state.isLoading = false;
                state.error = null;
            })
    },
});

export default usersSlice.reducer;
