import { createSlice } from '@reduxjs/toolkit';
import { register, logIn, logOut, refreshUser, updateProfileInfo } from './operations';

const handleRejected = (state, action) => {
    state.isLoading = false;
    state.error = true;
    state.errorMessage = action.payload;
};

const handlePending = state => {
    state.isLoading = true;
};


const initialState = {
    user: { firstName: null, lastName: null, email: null },
    isLoggedIn: false,
    isRefreshing: false,
    isLoading: false,
    error: false,
    errorMessage: ''
};

export const authSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: builder => {
        builder
            .addCase(logIn.pending, handlePending)
            .addCase(logIn.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isLoggedIn = true;
                state.error = false;
                state.errorMessage = '';

            })
            .addCase(logIn.rejected, handleRejected)
            .addCase(updateProfileInfo.pending, (state, action) => {
                state.isLoggedIn = true;
            })
            .addCase(updateProfileInfo.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;            
            })
            .addCase(updateProfileInfo.rejected, state => {
                state.isLoading = false;
            })
            .addCase(logOut.fulfilled, state => {
                state.user = { name: null, email: null };
                state.isLoggedIn = false;
            })
            .addCase(refreshUser.pending, state => {
                state.isRefreshing = true;
            })
            .addCase(refreshUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isLoggedIn = true;
                state.isRefreshing = false;
            })
            .addCase(refreshUser.rejected, state => {
                state.isRefreshing = false;
                state.isLoggedIn = false;
            });
    },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
